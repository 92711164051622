import React from "react";
import Header from "../Header/HeaderPage";
import { Outlet } from "react-router-dom";
import FooterPage from "../Footer/FooterPage";
import { zaloImg } from "../../../asset";

const RootLayout = ({ children }) => {
  return (
    <div className="positon-relative">
      <Header />
      <main
        className=" px-2 min-h-screen overflow-y-auto"
        style={{ paddingTop: "50px", zIndex: 1, position: "relative" }}
      >
        <Outlet />
      </main>
      <div className="button-contact-vr">
        <div className="zalo-vr button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a
                href="https://zalo.me/1925636855218148675"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt="zalo"
                  src={zaloImg}
                  className="phone-vr-img-circle-img"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <FooterPage />
    </div>
  );
};

export default RootLayout;
